var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "fill-height primary", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "", align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8", md: "6", lg: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "elevation-5 pa-3" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-snackbar",
                        {
                          attrs: {
                            timeout: 30000,
                            top: "",
                            color: _vm.model.color,
                            tile: "",
                          },
                          model: {
                            value: _vm.model.showResult,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "showResult", $$v)
                            },
                            expression: "model.showResult",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.model.resulte) + " ")]
                      ),
                      _c("div", { staticClass: "layout column align-center" }, [
                        _c("img", {
                          attrs: {
                            src: "img/icons/logo.png",
                            alt: "Logo Vitissia",
                            width: "241",
                            height: "115",
                          },
                        }),
                        _c(
                          "h1",
                          { staticClass: "text-center my-4 primary--text" },
                          [
                            _vm._v(" Votre compte vien d'être vérifié "),
                            _c("br"),
                            _c("br"),
                            _vm._v(
                              " Bienvenue parmis nous ! Veuillez vous authentifier "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-form",
                        [
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": "mdi-account",
                              name: "email",
                              label: _vm.$t("login.email"),
                              type: "email",
                              required: "",
                              autocomplete: "username",
                            },
                            model: {
                              value: _vm.model.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "email", $$v)
                              },
                              expression: "model.email",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": "mdi-lock",
                              name: "password",
                              label: _vm.$t("login.password"),
                              type: "password",
                              required: "",
                              autocomplete: "current-password",
                            },
                            model: {
                              value: _vm.model.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "password", $$v)
                              },
                              expression: "model.password",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [_c("localization")], 1),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    to: "/forgottenPassword",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("login.forgottenPassword")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    to: "/signup",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("login.signUp")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.loading,
                                  },
                                  on: { click: _vm.login },
                                },
                                [_vm._v(" Valider ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }