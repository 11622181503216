<template>
    <v-container fluid class="fill-height primary">
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <v-card class="elevation-5 pa-3">
                    <v-card-text>
                        <v-snackbar v-model="model.showResult" :timeout="30000" top :color="model.color" tile>
                            {{ model.resulte }}
                        </v-snackbar>
                        <div class="layout column align-center">
                            <img src="img/icons/logo.png" alt="Logo Vitissia" width="241" height="115">
                            <h1 class="text-center my-4 primary--text">
                                Votre compte vien d'être vérifié
                                <br>
                                <br>
                                Bienvenue parmis nous ! Veuillez vous authentifier
                            </h1>
                        </div>
                        <v-form>
                            <v-text-field v-model="model.email" append-icon="mdi-account" name="email"
                                :label="$t('login.email')" type="email" required autocomplete="username" />
                            <v-text-field v-model="model.password" append-icon="mdi-lock" name="password"
                                :label="$t('login.password')" type="password" required autocomplete="current-password" />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-col>
                                <localization />
                            </v-col>
                            <v-col>
                                <v-btn color="primary" outlined to="/forgottenPassword">
                                    {{ $t('login.forgottenPassword') }}
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn color="primary" outlined to="/signup">
                                    {{ $t('login.signUp') }}
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn color="primary" :loading="loading" @click="login">
                                    Valider
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Localization from '../widget/AppLocalization.vue';

export default {
    name: 'SignIn',
    components: { Localization },
    data: () => ({
        loading: false,
        model: {
            showResult: false,
            resulte: '',
            color: '',
            email: '',
            password: '',
        },
    }),
    methods: {
        async login() {
            if (this.model.email === '' || this.model.password === '') {
                this.model.showResult = true;
                this.model.color = 'red';
                this.model.resulte = 'Veuillez renseigner les champs nécessaires';
            } else {
                try {
                    await this.$store.dispatch('LoginByEmail', {
                        email: this.model.email,
                        password: this.model.password,
                    });
                    await this.$router.push(this.$route.query.redirect || '/');
                } catch {
                    this.model.showResult = true;
                    this.model.color = 'red';
                    this.model.resulte = 'Mot de passe ou adresse email incorrects';
                }
            }
        }
    }
};
</script>
